import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import AuthorizedNotFound from "../pages/AuthorizedNotFound";
import * as Cashiers from "../pages/Cashiers";
import * as EditCashiers from "../pages/Cashiers/Edit";
import * as EditUsers from "../pages/System/Users/Edit";
import * as Users from "../pages/System/Users";
import * as Sellers from "../pages/System/Sellers";
import * as EditSellers from "../pages/System/Sellers/Edit";
import * as Stores from "../pages/Store";
import * as EditStores from "../pages/Store/Edit";
import * as Languages from "../pages/System/Location/Language";
import * as EditLanguages from "../pages/System/Location/Language/Edit";
import * as EditCountries from "../pages/System/Location/Country/Edit";
import * as Countries from "../pages/System/Location/Country";
import * as EditCategory from "../pages/Catalog/Category/Edit";
import * as Category from "../pages/Catalog/Category";
import * as AttributeGroup from "../pages/Catalog/Attribute/AttributeGroup";
import * as EditAttributeGroup from "../pages/Catalog/Attribute/AttributeGroup/Edit";
import * as Attribute from "../pages/Catalog/Attribute";
import * as EditAttribute from "../pages/Catalog/Attribute/Edit";
import * as SpecificationGroup from "../pages/Catalog/Specification/SpecificationGroup";
import * as EditSpecificationGroup from "../pages/Catalog/Specification/SpecificationGroup/Edit";
import * as Specification from "../pages/Catalog/Specification";
import * as EditSpecification from "../pages/Catalog/Specification/Edit";
import * as NewProduct from "../pages/Catalog/Product/New";
import * as Product from "../pages/Catalog/Product";
import * as EditProduct from "../pages/Catalog/Product/Edit";
import * as ProductsParentChild from "../pages/Catalog/Product/ParentChild";
import * as EditProductsParentChild from "../pages/Catalog/Product/ParentChild/Edit";
import * as EditProductsParentChildOption from "../pages/Catalog/Product/ParentChild/OptionEdit";
import * as ProductsCombination from "../pages/Catalog/Product/Combination";
import * as EditProductsCombination from "../pages/Catalog/Product/Combination/Edit";
import * as Coupons from "../pages/Coupons";
import * as EditCoupons from "../pages/Coupons/Edit";
import * as Competitors from "../pages/Competitors";
import * as EditCompetitors from "../pages/Competitors/Edit";
import * as Brands from "../pages/Catalog/Brand";
import * as EditBrands from "../pages/Catalog/Brand/Edit";
import * as ShippingMethod from "../pages/ShippingMethods";
import * as EditShippingMethod from "../pages/ShippingMethods/Edit";
import * as ShippingMethodStores from "../pages/StoreLink/ShippingMethods";
import * as Currencies from "../pages/System/Location/Currency";
import * as EditCurrencies from "../pages/System/Location/Currency/Edit";
import * as ErrorsLog from "../pages/OptTools/ErrorsLog";
import * as Suppliers from "../pages/Catalog/Suppliers/";
import * as EditSuppliers from "../pages/Catalog/Suppliers/Edit";
import * as ProductSuppliers from "../pages/Catalog/Suppliers/ProductSupplier";
import * as EditProductSuppliers from "../pages/Catalog/Suppliers/ProductSupplier/Edit";
import * as ProductWarehouse from "../pages/ProductWarehouse";
import * as EditProductWarehouse from "../pages/ProductWarehouse/Edit";
import * as Warehouses from "../pages/Warehouses";
import * as EditWarehouses from "../pages/Warehouses/Edit";
import * as EditProductsBatch from "../pages/Catalog/Product/Batch";
import * as CategoryStores from "../pages/StoreLink/Category/index";
import * as ProductConditions from "../pages/Catalog/Product/Condition/index";
import * as EditProductConditions from "../pages/Catalog/Product/Condition/Edit/index";
import * as ProductStoresAll from "../pages/StoreLink/Product/index";
import * as ProductStoresStore from "../pages/StoreLink/Product/Store/index";
import * as ProductStoresPDVStore from "../pages/StoreLink/Product/PDVStore/index";
import * as ProductStoresWholesaleStore from "../pages/StoreLink/Product/WholesaleStore/index";
import * as ProductStoresWholesalePDVStore from "../pages/StoreLink/Product/WholesalePDVStore/index";
import * as PaymentMethod from "../pages/PaymentMethods/index";
import * as EditPaymentMethod from "../pages/PaymentMethods/Edit/index";
import * as PaymentMethodStores from "../pages/StoreLink/Payment/index";
import * as Customers from "../pages/Sales/Customers";
import * as EditCustomers from "../pages/Sales/Customers/Edit";
import * as CustomerGroups from "../pages/Sales/Customers/CustomerGroups";
import * as EditCustomerGroups from "../pages/Sales/Customers/CustomerGroups/Edit";
import * as ListCustomerCustomerGroups from "../pages/Sales/Customers/CustomerCustomerGroups";
import * as ListContacts from "../pages/Sales/Contacts";
import * as EditContact from "../pages/Sales/Contacts/Edit";
import * as Carts from "../pages/Sales/Carts";
import * as EditCart from "../pages/Sales/Carts/Edit";
import * as Orders from "../pages/Sales/Orders";
import * as NewOrder from "../pages/Sales/Orders/New";
import * as EditOrder from "../pages/Sales/Orders/Edit";
import * as OrderStatus from "../pages/Sales/Orders/Status";
import * as EditOrderStatus from "../pages/Sales/Orders/Status/Edit";
import * as NewOrderStatusHistory from "../pages/Sales/Orders/StatusHistory";
import * as ProductInventoryStoreReport from "../pages/OptTools/Reports/ProductInventoryStore";
import * as Pages from "../pages/Store/Page";
import * as EditPage from "../pages/Store/Page/Edit";
import * as ExportProductEbay from "../pages/Catalog/Product/TemplateExport/Ebay";

export const normalRoutes = [
  {
    path: "/login",
    exact: true,
    component: Login,
    redirect: "/login",
  },
];
export const authorizedRoutes = [
  {
    path: "/",
    exact: true,
    permissions: [],
    component: Dashboard,
    redirect: "/login",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/coupons",
    exact: true,
    permissions: [],
    component: Coupons.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/coupons/:id",
    exact: true,
    permissions: [],
    component: EditCoupons.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/competitors",
    exact: true,
    permissions: [],
    component: Competitors.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/competitors/:id",
    exact: true,
    permissions: [],
    component: EditCompetitors.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/shipping-methods",
    exact: true,
    permissions: [],
    component: ShippingMethod.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/shipping-methods/:id",
    exact: true,
    permissions: [],
    component: EditShippingMethod.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/shipping-method-stores",
    exact: true,
    permissions: [],
    component: ShippingMethodStores.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  //product-qty-warehouse
  {
    path: "/product-qty-warehouse",
    exact: true,
    permissions: [],
    component: ProductWarehouse.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-qty-warehouse/:id",
    exact: true,
    permissions: [],
    component: EditProductWarehouse.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-stores",
    exact: true,
    permissions: [],
    component: ProductStoresAll.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-stores/store",
    exact: true,
    permissions: [],
    component: ProductStoresStore.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-stores/pdvstore",
    exact: true,
    permissions: [],
    component: ProductStoresPDVStore.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-stores/wholesalestore",
    exact: true,
    permissions: [],
    component: ProductStoresWholesaleStore.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-stores/wholesalepdvstore",
    exact: true,
    permissions: [],
    component: ProductStoresWholesalePDVStore.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/warehouses",
    exact: true,
    permissions: [],
    component: Warehouses.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/warehouses/:id",
    exact: true,
    permissions: [],
    component: EditWarehouses.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  /*  {
    path: "/product-qty-warehouse/:id",
    exact: true,
    permissions: [],
    component: EditShippingMethod.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  }, */

  {
    path: "/users",
    exact: true,
    permissions: [],
    component: Users.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/users/:id",
    exact: true,
    permissions: [],
    component: EditUsers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/sellers",
    exact: true,
    permissions: [],
    component: Sellers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/sellers/:id",
    exact: true,
    permissions: [],
    component: EditSellers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/stores",
    exact: true,
    permissions: [],
    component: Stores.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/stores/:id",
    exact: true,
    permissions: [],
    component: EditStores.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/languages",
    exact: true,
    permissions: [],
    component: Languages.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/languages/:id",
    exact: true,
    permissions: [],
    component: EditLanguages.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/countries/:id",
    exact: true,
    permissions: [],
    component: EditCountries.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/countries",
    exact: true,
    permissions: [],
    component: Countries.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  //MUITO IMPORTANTE
  //Routes NEW devem vir antes do que as :id
  {
    path: "/categories/:id",
    exact: true,
    permissions: [],
    component: EditCategory.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/categories",
    exact: true,
    permissions: [],
    component: Category.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/attribute-groups",
    exact: true,
    permissions: [],
    component: AttributeGroup.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/attribute-groups/:id",
    exact: true,
    permissions: [],
    component: EditAttributeGroup.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/attributes",
    exact: true,
    permissions: [],
    component: Attribute.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/attributes/:id",
    exact: true,
    permissions: [],
    component: EditAttribute.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/specification-groups",
    exact: true,
    permissions: [],
    component: SpecificationGroup.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/specification-groups/:id",
    exact: true,
    permissions: [],
    component: EditSpecificationGroup.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/specifications",
    exact: true,
    permissions: [],
    component: Specification.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/specifications/:id",
    exact: true,
    permissions: [],
    component: EditSpecification.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products",
    exact: true,
    permissions: [],
    component: Product.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/new",
    exact: true,
    permissions: [],
    component: NewProduct.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/product-parent-childs",
    exact: true,
    permissions: [],
    component: ProductsParentChild.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/product-parent-childs/:id",
    exact: true,
    permissions: [],
    component: EditProductsParentChild.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/product-parent-child-options/:id",
    exact: true,
    permissions: [],
    component: EditProductsParentChildOption.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/product-combinations",
    exact: true,
    permissions: [],
    component: ProductsCombination.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/product-combinations/:id",
    exact: true,
    permissions: [],
    component: EditProductsCombination.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/batch-update",
    exact: true,
    permissions: [],
    component: EditProductsBatch.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/:id",
    exact: true,
    permissions: [],
    component: EditProduct.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-conditions",
    exact: true,
    permissions: [],
    component: ProductConditions.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-conditions/:id",
    exact: true,
    permissions: [],
    component: EditProductConditions.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/cashiers",
    exact: true,
    permissions: [],
    component: Cashiers.default,
    //role_id: 0,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/cashiers/:id",
    exact: true,
    permissions: [],
    component: EditCashiers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/brands",
    exact: true,
    permissions: [],
    component: Brands.default,
    //role_id: 0,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/brands/:id",
    exact: true,
    permissions: [],
    component: EditBrands.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/currencies",
    exact: true,
    permissions: [],
    component: Currencies.default,
    //role_id: 0,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/currencies/:id",
    exact: true,
    permissions: [],
    component: EditCurrencies.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/errorslogs",
    exact: true,
    permissions: [],
    component: ErrorsLog.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/suppliers",
    exact: true,
    permissions: [],
    component: Suppliers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/suppliers/:id",
    exact: true,
    permissions: [],
    component: EditSuppliers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-suppliers",
    exact: true,
    permissions: [],
    component: ProductSuppliers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-suppliers/:id",
    exact: true,
    permissions: [],
    component: EditProductSuppliers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/category-stores",
    exact: true,
    permissions: [],
    component: CategoryStores.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/payment-methods",
    exact: true,
    permissions: [],
    component: PaymentMethod.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/payment-methods/:id",
    exact: true,
    permissions: [],
    component: EditPaymentMethod.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/payment-method-stores",
    exact: true,
    permissions: [],
    component: PaymentMethodStores.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/customers",
    exact: true,
    permissions: [],
    component: Customers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/customers/:id",
    exact: true,
    permissions: [],
    component: EditCustomers.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/customer-groups",
    exact: true,
    permissions: [],
    component: CustomerGroups.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/customer-groups/:id",
    exact: true,
    permissions: [],
    component: EditCustomerGroups.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/customer-customer-groups", // TO DO finish later
    exact: true,
    permissions: [],
    component: ListCustomerCustomerGroups.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/website-contacts",
    exact: true,
    permissions: [],
    component: ListContacts.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/website-contacts/:id",
    exact: true,
    permissions: [],
    component: EditContact.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/carts",
    exact: true,
    permissions: [],
    component: Carts.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/carts/:id",
    exact: true,
    permissions: [],
    component: EditCart.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/orders/",
    exact: true,
    permissions: [],
    component: Orders.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },

  {
    path: "/orders/new",
    exact: true,
    permissions: [],
    component: NewOrder.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/orders/status-history/:id",
    exact: true,
    permissions: [],
    component: NewOrderStatusHistory.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/orders/:id",
    exact: true,
    permissions: [],
    component: EditOrder.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/order-status/",
    exact: true,
    permissions: [],
    component: OrderStatus.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/order-status/:id",
    exact: true,
    permissions: [],
    component: EditOrderStatus.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/product-inventory-store-report",
    exact: true,
    permissions: [],
    component: ProductInventoryStoreReport.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/page",
    exact: true,
    permissions: [],
    component: Pages.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/page/:id",
    exact: true,
    permissions: [],
    component: EditPage.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
  {
    path: "/products/export-ebay/:id",
    exact: true,
    permissions: [],
    component: ExportProductEbay.default,
    redirect: "/",
    unauthorized: AuthorizedNotFound,
  },
];
/*
export const paths = [
  {
    path: "/order",
    component: Login,
    role_id: 0,
  },
  {
    path: "/orders/:id",
    component: Login,
    role_id: 0,
  },
  {
    path: "/products/new",
    component: NewProduct,
    role_id: 0,
  },
];
export const DASHBOARD_PATH = "/";
export const LOGIN_PATH = "/login";
*/
