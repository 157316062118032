/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  DataGrid,
  GridRowModel,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert, { AlertProps } from "@mui/material/Alert";
import { LogTrackUser } from "../../../../types/log-track-user/log-track-user";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import {
  Box,
  CardMedia,
  Container,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { AsyncPaginate } from "react-select-async-paginate";
import Swal from "sweetalert2";
import moment from "moment";
import { MultiValue } from "react-select";
import Cookies from "js-cookie";

interface ProductWholesaleStores {
  product_id: number;
  sku: string;
  product: string;
  store_id: number;
  store_name: string;
  store: string;
  currency_code: string;
  sell_unit: string;
  product_wholesale_store_price: number;
  product_wholesale_store_status: number;
  product_wholesale_store_status_text: string;
  product_wholesale_store_price_sale: number;
  product_wholesale_store_is_sale: boolean;
  product_wholesale_store_sale_begin: Date;
  product_wholesale_store_sale_end: Date;
}

//DATA GRID FUNCTIONS

const useExecuteMutation = () => {
  return React.useCallback(
    (productStore: Partial<ProductWholesaleStores>) =>
      new Promise<Partial<ProductWholesaleStores>>((resolve, reject) =>
        setTimeout(() => {
          if (productStore.product_wholesale_store_price === 0) {
            reject();
          } else {
            resolve(productStore);
          }
        }, 200)
      ),
    []
  );
};

function SelectEditStatus(props: GridRenderCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChangeStatus = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      value={value}
      onChange={handleChangeStatus}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus
    >
      <option>DISABLED</option>
      <option>ACTIVE</option>
      <option>ACTIVE, BUT NOT BUYABLE</option>
      <option>ACTIVE, BUYABLE, DO NOT SHOW ON CATALOG</option>
    </Select>
  );
}

const renderSelectEditStatus: GridColDef["renderCell"] = (params) => {
  return <SelectEditStatus {...params} />;
};

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  //Price Section
  if (
    newRow.product_wholesale_store_price !==
    oldRow.product_wholesale_store_price
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' PDV Store Price from '${oldRow.product_wholesale_store_price}' to '${newRow.product_wholesale_store_price}'`;
  }
  //Status Section
  if (
    newRow.product_wholesale_store_status_text !==
    oldRow.product_wholesale_store_status_text
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' PDV Store Status from '${oldRow.product_wholesale_store_status_text}' to '${newRow.product_wholesale_store_status_text}'`;
  }
  //Price Sale
  if (
    newRow.product_wholesale_store_price_sale !==
    oldRow.product_wholesale_store_price_sale
  ) {
    //Check if the SALE price is bigger than the regular price. if it is, force price_sale = price
    if (
      newRow.product_wholesale_store_price_sale >
      newRow.product_wholesale_store_price
    ) {
      newRow.product_wholesale_store_price_sale =
        newRow.product_wholesale_store_price;
    }
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' PDV Store Price from '${oldRow.product_wholesale_store_price_sale}' to '${newRow.product_wholesale_store_price_sale}'`;
  }
  //SALE Begin at:
  if (
    newRow.product_wholesale_store_sale_begin !==
    oldRow.product_wholesale_store_sale_begin
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' PDV Store Sort Order from '${oldRow.product_wholesale_store_sale_begin}' to '${newRow.product_wholesale_store_sale_begin}'`;
  }
  //SALE Ends at:
  if (
    newRow.product_wholesale_store_sale_end !==
    oldRow.product_wholesale_store_sale_end
  ) {
    return `Product: '${oldRow.product}', Store: '${oldRow.store}' PDV Store Sort Order from '${oldRow.product_wholesale_store_sale_end}' to '${newRow.product_wholesale_store_sale_end}'`;
  }
  return null;
}

export default function ListProductStoresWholesale() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////
  const [loading, setLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const [rowCountState, setRowCountState] = React.useState(0);
  const [productWholesaleStore, setProductWholesaleStore] = useState<
    {
      product_id: number;
      sku: string;
      product: string;
      store_id: number;
      store_name: string;
      store: string;
      currency_code: string;
      sell_unit: string;
      product_wholesale_store_price: number;
      product_wholesale_store_status: number;
      product_wholesale_store_status_text: string;
      product_wholesale_store_price_sale: number;
      product_wholesale_store_is_sale: boolean;
      product_wholesale_store_sale_begin: Date;
      product_wholesale_store_is_sale_end: Date;
      created_at: Date;
      updated_at: Date;
    }[]
  >([]);

  const fetchData = async (page = 1, numberItemsPerPage = 100) => {
    await api
      .get(
        `/product-stores/wholesalestore?page=${page}
        &limit=${numberItemsPerPage}
        &product_sku=${productSKU}&store_id=${store}&price_min=${priceMin}&price_max=${priceMax}
        &status=${status}`
      )
      .then((res) => {
        setProductWholesaleStore(res.data.items);
        //setRows(res.data.items);
        //setTotalPages(res.data.total_pages);
        setRowCountState(res.data.total);
      });
  };

  //Product SKU Filter
  const [productSKU, setProductSKU] = useState("");
  const handleChangeProductSKU = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductSKU(e.target.value);
  };

  //Stores Filter
  const [store, setStore] = useState(0);
  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(Number(event.target.value));
  };
  const [stores, setStores] = useState<{ id: number; name: string }[]>([]);
  const fetchStores = async () => {
    const response = await api.get(`stores?page=0&limit=500&language_id=1`);
    setStores(response.data.items);
  };

  //Price Min Filter
  const [priceMin, setPriceMin] = useState(0);
  const handleChangePriceMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceMin(Number(e.target.value));
  };

  //Price Max Filter
  const [priceMax, setPriceMax] = useState(0);
  const handleChangePriceMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceMax(Number(e.target.value));
  };

  //Status Filter
  const [status, setStatus] = useState(10);
  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(Number(event.target.value));
  };

  const handleSearchClick = () => {
    // fetchData(1, Number(numberItemsPerPage));
    fetchData();
  };

  //New Product Store Section
  const [newProductStoreProduct, setNewProductStoreProduct] = useState<{
    id: number;
    sku: string;
    image: string;
  } | null>(null);
  const [newProductStoreStore, setNewProductStoreStore] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [newProductStorePrice, setNewProductStorePrice] = useState(0);

  const loadOptionsProducts = async (search: any, page: any) => {
    const results = await api.get(
      `/products-custom?page=${page}&search=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProducts = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProducts(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadOptionsStores = async (search: any, page: any) => {
    const results = await api.get(`/stores?page=${page}&search=${search}`);
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsStores(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const defaultAdditional = {
    page: 1,
  };

  const handleNewClick = async () => {
    if (
      newProductStoreProduct &&
      newProductStoreStore &&
      newProductStorePrice > 0
    ) {
      let productStore = {} as any;
      productStore.product_id = newProductStoreProduct?.id;
      productStore.store_id = newProductStoreStore?.id;
      productStore.price = newProductStorePrice;
      productStore.product_condition_id = 1;
      productStore.status = 0;
      productStore.show_catalog = true;

      api
        .post(`product-stores`, productStore)
        .then((response) => {
          Swal.fire({
            title: "Product Store Created!",
            icon: "success",
            confirmButtonText: "Yes",
          });
          // fetchData(pageState, Number(numberItemsPerPage));
          fetchData();
        })
        .catch((error) => {
          Swal.fire({
            title: "Ops...",
            icon: "error",
            text: error.response.data.message,
            confirmButtonText: "Yes",
          });
        });
    } else {
      Swal.fire({
        title: "Ops...",
        icon: "error",
        text: "Product, Store must be defined and Price must be > 0.",
        confirmButtonText: "Yes",
      });
    }
  };

  //DATA GRID SECTION
  const mutateRow = useExecuteMutation();
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      // Make the HTTP request to save in the backend

      let productWholesaleStoreUpdate = {} as any;
      productWholesaleStoreUpdate.product_id = newRow.product_id;
      productWholesaleStoreUpdate.store_id = newRow.store_id;
      productWholesaleStoreUpdate.updated_at = new Date().toISOString();
      let username = Cookies.get("username") ? Cookies.get("username") : "";
      productWholesaleStoreUpdate.username = username;
      productWholesaleStoreUpdate.sell_unit = newRow.sell_unit;
      //Check What has been changed, so the appropriateAPI is called
      if (
        newRow.product_wholesale_store_price !==
        oldRow.product_wholesale_store_price
      ) {
        productWholesaleStoreUpdate.price =
          newRow.product_wholesale_store_price;
      }

      //Status Section
      if (
        newRow.product_wholesale_store_status_text !==
        oldRow.product_wholesale_store_status_text
      ) {
        switch (
          String(newRow.product_wholesale_store_status_text).toLowerCase()
        ) {
          case "active, buyable, do not show on catalog":
            productWholesaleStoreUpdate.status = 3;
            newRow.product_wholesale_store_status = 3;
            break;
          case "active, but not buyable":
            productWholesaleStoreUpdate.status = 2;
            newRow.product_wholesale_store_status = 2;
            break;
          case "active":
            productWholesaleStoreUpdate.status = 1;
            newRow.product_wholesale_store_status = 1;
            break;
          default:
            productWholesaleStoreUpdate.status = 0;
            newRow.product_wholesale_store_status = 0;
        }
      }

      //Price Sale
      if (
        newRow.product_wholesale_store_price_sale !==
        oldRow.product_wholesale_store_price_sale
      ) {
        //Check if the SALE price is bigger than the regular price. if it is, force price_sale = price
        if (
          newRow.product_wholesale_store_price_sale >
          newRow.product_wholesale_store_price
        ) {
          newRow.product_wholesale_store_price_sale =
            newRow.product_wholesale_store_price;
        }
        productWholesaleStoreUpdate.price_sale =
          newRow.product_wholesale_store_price_sale;
      }
      //SALE Begin at:
      if (
        newRow.product_wholesale_store_sale_begin !==
        oldRow.product_wholesale_store_sale_begin
      ) {
        productWholesaleStoreUpdate.sale_begin =
          newRow.product_wholesale_store_sale_begin;
      }
      //SALE Ends at:
      if (
        newRow.product_wholesale_store_sale_end !==
        oldRow.product_wholesale_store_sale_end
      ) {
        productWholesaleStoreUpdate.sale_end =
          newRow.product_wholesale_store_sale_end;
      }

      await api.put(
        `product-stores/productwholesalestore`,
        productWholesaleStoreUpdate
      );

      const response = await mutateRow(newRow);
      setSnackbar({
        children: "Product Wholesale Store successfully saved",
        severity: "success",
      });
      resolve(response);
      setPromiseArguments(null);
      //fetchData(pageState, numberItemsPerPage);
    } catch (error) {
      setSnackbar({
        children: "Product Wholesale Store fields can't be empty",
        severity: "error",
      });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  //BATCH EDIT SECTION

  //New Product Store Section
  const [batcheditProductWholesaleStore, setBatcheditProductWholesaleStore] =
    useState<
      {
        product_id: number;
        sku: string;
        store_id: number;
        store_name: string;
        image: string;
      }[]
    >([]);
  const onChangeBatcheditProductWholesaleStore = async (
    newValue: MultiValue<{
      product_id: number;
      sku: string;
      store_id: number;
      store_name: string;
      image: string;
    }>
  ) => {
    setBatcheditProductWholesaleStore([...newValue]);
  };

  const loadOptionsProductWholesaleStores = async (search: any, page: any) => {
    const results = await api.get(
      `/product-stores/wholesalestore?page=${page}&product_sku=${search}`
    );
    const hasMore = results.data.total_pages === results.data.current_page;
    return {
      options: results.data.items,
      hasMore: !hasMore,
    };
  };

  const loadPageOptionsProductWholesaleStores = async (
    q: any,
    _prevOptions: any,
    { page }: any
  ) => {
    const { options, hasMore } = await loadOptionsProductWholesaleStores(
      q,
      page
    );
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  //Status
  const [batcheditStatus, setBatcheditStatus] = useState(-1);

  //SALE Begin
  const [batcheditSaleBegin, setBatcheditSaleBegin] = useState("");

  //SALE End
  const [batcheditSaleEnd, setBatcheditSaleEnd] = useState("");

  const handleBatchEditClick = async () => {
    Swal.fire({
      title: `Are you sure to Batch Update?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, UPDATE it ALL!",
    }).then((result) => {
      if (result.isConfirmed) {
        batcheditProductWholesaleStore.map(async (item, index) => {
          let productWholesaleStoreUpdate = {} as any;
          productWholesaleStoreUpdate.product_id = item.product_id;
          productWholesaleStoreUpdate.store_id = item.store_id;

          if (batcheditStatus > 0) {
            productWholesaleStoreUpdate.status = batcheditStatus;
          }

          if (batcheditSaleBegin) {
            productWholesaleStoreUpdate.sale_begin = batcheditSaleBegin;
          }
          if (batcheditSaleEnd) {
            productWholesaleStoreUpdate.sale_end = batcheditSaleEnd;
          }

          productWholesaleStoreUpdate.updated_at = new Date().toISOString();
          let username = Cookies.get("username") ? Cookies.get("username") : "";
          productWholesaleStoreUpdate.username = username;

          api
            .put(
              `product-stores/productwholesalestore`,
              productWholesaleStoreUpdate
            )
            .catch((error) => {
              Swal.fire({
                title: "Ops...",
                icon: "error",
                text: error.response.data.message,
                confirmButtonText: "Yes",
              });
            });
        });
      }
    });
    setTimeout(function () {
      // fetchData(pageState, Number(numberItemsPerPage));
      fetchData();
    }, 2000);
  };

  //LOAD INITIAL VALUES
  useEffect(() => {
    fetchData();
    fetchStores();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      fetchData(paginationModel.page + 1, paginationModel.pageSize);

      if (!active) {
        return;
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page]);

  return (
    <Container maxWidth="xl" sx={{ width: "100%", mt: 2, mb: 4 }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          paddingTop: "40px",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#556cd6",
          paddingLeft: "20px",
        }}
      >
        Product & Store Prices - Wholesale Store
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="number-items-page-label">
            Filters: Product SKU
          </InputLabel>
          <TextField
            id={"productSKU"}
            type="text"
            label="Product SKU filter"
            value={productSKU}
            onChange={handleChangeProductSKU}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="store-select-label">Store</InputLabel>
          <Select
            labelId="store-select-label"
            id="store"
            value={String(store)}
            label="Store"
            onChange={handleChangeStore}
          >
            <MenuItem value={0}>None</MenuItem>
            {stores.map((store, index) => (
              <MenuItem value={store.id} key={index}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
            width: 1 / 8,
          }}
        >
          <InputLabel id="priceMin-select-label">Minimum Price</InputLabel>
          <TextField
            id={"priceMin"}
            type="number"
            label="Minimum Price filter"
            value={priceMin}
            onChange={handleChangePriceMin}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
            width: 1 / 8,
          }}
        >
          <InputLabel id="priceMax-select-label">Maximum Price</InputLabel>
          <TextField
            id={"priceMax"}
            type="number"
            label="Maximum Price filter"
            value={priceMax}
            onChange={handleChangePriceMax}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
          }}
        >
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status"
            value={String(status)}
            label="Status"
            onChange={handleChangeStatus}
          >
            <MenuItem value={10}>Don`t Filter</MenuItem>
            <MenuItem value={0}>Disabled</MenuItem>
            <MenuItem value={1}>Enabled</MenuItem>
            <MenuItem value={2}>Active but not Buyable</MenuItem>
            <MenuItem value={3}>
              Active, Buyable, but do not show on Catalog
            </MenuItem>
          </Select>
        </Box>

        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>
      To insert a new Entry, please fill the fields below and press the Insert
      Button
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="productstore-product-label">Product:</InputLabel>
          <AsyncPaginate
            placeholder="Select Product"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productstore_product"}
            id="productstore_product"
            name="productstore_product"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.sku}
            loadOptions={loadPageOptionsProducts}
            value={newProductStoreProduct}
            onChange={setNewProductStoreProduct}
          />
          <CardMedia
            component="img"
            sx={{ width: 100, display: "inline" }}
            image={`${imageBaseURL}${newProductStoreProduct?.image}`}
            alt={"image"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="productstore-store-label">Store:</InputLabel>
          <AsyncPaginate
            placeholder="Select Store"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
            }}
            additional={defaultAdditional}
            key={"productstore_store"}
            id="productstore_store"
            name="productstore_store"
            getOptionValue={(option: any) => option?.id}
            getOptionLabel={(option: any) => option?.name}
            loadOptions={loadPageOptionsStores}
            value={newProductStoreStore}
            onChange={setNewProductStoreStore}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 4,
          }}
        >
          <InputLabel id="price-store-label">Initial Price:</InputLabel>
          <TextField
            id={"productstore_price"}
            type="number"
            label="Initial Price"
            value={newProductStorePrice}
            onChange={(event) =>
              setNewProductStorePrice(Number(event.target.value))
            }
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleNewClick}
        >
          New Entry
        </Button>
      </Box>
      All fields, except "Product ID, SKU, Store, Currency, Is SALE?" are
      changeable. To change any of it, DOUBLE CLICK on the row field.<br></br>
      Field Is SALE is updated automatically once the following criteria is met:
      <br></br>- SALE Being at: field &#8804; Current time<br></br>- SALE Ends
      at: field &#8805; Current time<br></br>- Price SALE: field &#62; 0
      <br></br>
      <div style={{ height: 600, width: "100%" }}>
        {renderConfirmDialog()}
        <DataGrid
          rows={productWholesaleStore}
          columns={columns}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel,
            },
          }}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[25, 50, 100]}
          rowCount={rowCountState}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          loading={loading}
        />
        {!!snackbar && (
          <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </div>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          paddingTop: "40px",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#556cd6",
          paddingLeft: "20px",
        }}
      >
        Product & Store Prices - Wholesale Store - Batch Edit
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginRight: "8px",
          marginLeft: 1,
          minWidth: 1 / 2,
        }}
      >
        <InputLabel id="BatchEditProductStore-label">
          Product / Store:
        </InputLabel>
        <AsyncPaginate
          isMulti
          closeMenuOnSelect={false}
          placeholder="Select Products & Stores - More than 1 can be selected"
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
          }}
          additional={defaultAdditional}
          key={"batchedit_product_wholesale_store"}
          id="defaultAdditional"
          name="defaultAdditional"
          getOptionValue={(option: any) => option?.id}
          getOptionLabel={(option: any) =>
            option?.sku + "/" + option?.store_name
          }
          loadOptions={loadPageOptionsProductWholesaleStores}
          value={batcheditProductWholesaleStore}
          onChange={onChangeBatcheditProductWholesaleStore}
        />
        {batcheditProductWholesaleStore.map((input, index) => {
          return (
            <p key={index}>
              Product: {input.product_id} / {input.sku} - Store:{" "}
              {input.store_id} / {input.store_name}
              <br></br>
              <CardMedia
                component="img"
                sx={{ width: 100, display: "inline" }}
                image={`${imageBaseURL}${input.image}`}
                alt={"image"}
              />
            </p>
          );
        })}
      </Box>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="batcheditStatus-select-label">Status</InputLabel>
          <Select
            labelId="batcheditStatus-select-label"
            id="batcheditStatus"
            value={String(batcheditStatus)}
            label="Status"
            onChange={(event) => setBatcheditStatus(Number(event.target.value))}
          >
            <MenuItem value={-1}>Don`t Apply</MenuItem>
            <MenuItem value={0}>Disabled</MenuItem>
            <MenuItem value={1}>Enabled</MenuItem>
            <MenuItem value={2}>Active but not Buyable</MenuItem>
            <MenuItem value={3}>
              Active, Buyable, but do not show on Catalog
            </MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="salebegin-store-label">SALE Begins at:</InputLabel>
          <TextField
            InputLabelProps={{ shrink: true }}
            id={"batcheditSaleBegin"}
            type="datetime-local"
            label="SALE Begins at:"
            value={batcheditSaleBegin}
            onChange={(event) => setBatcheditSaleBegin(event.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: 1,
            minWidth: 1 / 8,
          }}
        >
          <InputLabel id="saleend-store-label">SALE Ends at:</InputLabel>
          <TextField
            InputLabelProps={{ shrink: true }}
            id={"batcheditSaleEnd"}
            type="datetime-local"
            label="SALE Ends at:"
            value={batcheditSaleEnd}
            onChange={(event) => setBatcheditSaleEnd(event.target.value)}
          />
        </Box>
        <Button
          variant="contained"
          sx={{ m: 3 }}
          href=""
          onClick={handleBatchEditClick}
        >
          Batch Edit
        </Button>
      </Box>
    </Container>
  );
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

//Image Folder
const imageBaseURL = process.env.REACT_APP_IMG_CATALOG_URL;

const columns: GridColDef[] = [
  { field: "product_id", headerName: "Product ID", width: 80, editable: false },
  {
    field: "sku",
    headerName: "Product SKU",
    width: 180,
    editable: false,
  },
  {
    field: "image",
    headerName: "Image",
    width: 100,
    //renderCell: (params) => <img alt={params.value} src={imageBaseURL+params.value} />
    renderCell: (params) => {
      return (
        <CardMedia
          component="img"
          sx={{ width: 100, display: "inline" }}
          image={`${imageBaseURL}${params.value}`}
          alt={"image"}
        />
      );
    },
  }, // renderCell will render the component },
  { field: "store_name", headerName: "Store", width: 150, editable: false },
  {
    field: "currency_code",
    headerName: "Currency",
    width: 100,
    editable: false,
  },
  {
    field: "product_wholesale_store_status_text",
    headerName: "Status",
    type: "string",
    width: 200,
    editable: true,
    renderEditCell: renderSelectEditStatus,
  },
  {
    field: "product_wholesale_store_price",
    headerName: "Price",
    type: "number",
    width: 80,
    editable: true,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
  },
  {
    field: "sell_unit",
    headerName: "Unit",
    type: "string",
    width: 50,
    editable: false,
  },
  {
    field: "product_wholesale_store_price_sale",
    headerName: "Price SALE",
    type: "number",
    width: 100,
    editable: true,
    valueFormatter: ({ value }) => currencyFormatter.format(value),
  },
  {
    field: "product_wholesale_store_is_sale",
    headerName: "Is SALE?",
    type: "boolean",
    width: 80,
    editable: false,
  },
  {
    field: "product_wholesale_store_sale_begin",
    headerName: "SALE Begin at:",
    type: "dateTime",
    width: 180,
    editable: true,
    valueFormatter: (params) =>
      moment(params?.value).format("DD/MM/YYYY hh:mm:ss"), // This is needed in order to show adjusted to the local time
  },
  {
    field: "product_wholesale_store_sale_end",
    headerName: "SALE Ends at:",
    type: "dateTime",
    width: 180,
    editable: true,
    valueFormatter: (params) =>
      moment(params?.value).format("DD/MM/YYYY hh:mm:ss"), // This is needed in order to show adjusted to the local time
  },

  /*   {
    field: 'lastLogin',
    headerName: 'Last Login',
    type: 'dateTime',
    width: 220,
  }, */
];
