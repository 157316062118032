import IconDashboard from "@mui/icons-material/Dashboard";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
//import AddLocationIcon from "@mui/icons-material/AddLocation";
import InventoryIcon from "@mui/icons-material/Inventory";
import CategoryIcon from "@mui/icons-material/Category";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import AttributionIcon from "@mui/icons-material/Attribution";
import MeliIcon from "../icon/MeliIcon";
import LanguageIcon from "@mui/icons-material/Language";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import B2wIcon from "../icon/B2wIcon";
import AmazonIcon from "../icon/AmazonIcon";
import ShopeeIcon from "../icon/ShopeeIcon";
import PublicIcon from "@mui/icons-material/Public";
import {
  AddCircle,
  AddShoppingCart,
  Assessment,
  DeliveryDining,
  Feed,
  MoreHoriz,
  Payments,
  SendTimeExtension,
  ShoppingBag,
  Summarize,
} from "@mui/icons-material";
import SosMascarasIcon from "../icon/SosMascarasIcon";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import CatchingPokemonIcon from "@mui/icons-material/CatchingPokemon";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ErrorIcon from "@mui/icons-material/Error";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleIcon from "@mui/icons-material/People";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import TainaraVictoryIcon from "../icon/TainaraVictoryIcon";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ShopIcon from "@mui/icons-material/Shop";
import Shop2Icon from "@mui/icons-material/Shop2";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ShopFromBrazil from "../icon/ShopFromBrazil";

// import { AxiosResponseStores } from "../../types/stores";
/* 
const fetchDataStores = async () => {
  const stores = await api.get<AxiosResponseStores>(`/stores`);

  return stores;
};

const stores = fetchDataStores().then((stores) => {
  const storeItems = stores.data.items.map((store) => ({
    name: store.name,
    items: [
      {
        name: "Orders",
        link: `/orders?store=${store.id}`,
      },
      {
        name: "Customers",
        link: `/customers?store=${store.id}`,
      },
    ],
  }));
  return storeItems;
}); */

const Items = [
  {
    name: "Dashboard",
    link: "/",
    Icon: IconDashboard,
    items: [],
  },
  {
    name: "Products Info",
    Icon: LibraryBooks,
    items: [
      {
        name: "Products",
        Icon: InventoryIcon,
        items: [
          {
            name: "New Product",
            link: "/products/new",
            Icon: AddCircle,
          },
          {
            name: "List Products",
            link: "/products",
            Icon: FormatListBulletedIcon,
          },
          {
            name: "Parent Child",
            Icon: GroupWorkIcon,
            link: "/products/product-parent-childs",
          },
          {
            name: "Combination",
            Icon: WorkspacesIcon,
            link: "/products/product-combinations",
          },
        ],
      },

      {
        name: "Brands",
        Icon: MapsUgcIcon,
        link: "/brands",
      },
      {
        name: "Categories",
        Icon: CategoryIcon,
        link: "/categories",
      },
      {
        name: "Suppliers",
        Icon: AddBusinessIcon,
        items: [
          {
            name: "Suppliers",
            Icon: GroupIcon,
            link: "/suppliers",
          },
          {
            name: "Product Suppliers",
            Icon: InventoryIcon,
            link: "/product-suppliers",
          },
        ],
      },
      {
        name: "Attributes",
        Icon: AccountTreeIcon,
        items: [
          {
            name: "Attribute Groups",
            link: "/attribute-groups",
            Icon: GroupWorkIcon,
          },
          {
            name: "Attributes",
            link: "/attributes",
            Icon: AttributionIcon,
          },
        ],
      },
      {
        name: "Specifications",
        Icon: EditAttributesIcon,
        items: [
          {
            name: "Specification Groups",
            Icon: GroupWorkIcon,
            link: "/specification-groups",
          },
          {
            name: "Specifications",
            Icon: AttributionIcon,
            link: "/specifications",
          },
        ],
      },
      {
        name: "Batch Product Update",
        link: "/products/batch-update",
        Icon: BatchPredictionIcon,
      },
    ],
  },
  {
    name: "Inventory",
    Icon: InventoryIcon,
    items: [
      {
        name: "Warehouses",
        Icon: WarehouseIcon,
        link: "/warehouses",
      },
      {
        name: "Products Inventory",
        Icon: PrecisionManufacturingIcon,
        link: "/product-qty-warehouse",
      },
    ],
  },
  {
    name: "Store Link & Prices",
    Icon: StoreIcon,
    items: [
      {
        name: "Categories",
        Icon: CategoryIcon,
        link: "/category-stores",
      },
      {
        name: "Prices",
        Icon: PriceChangeIcon,
        link: "/product-stores",
        items: [
          {
            name: "Online Store",
            Icon: ShopIcon,
            link: "/product-stores/store",
          },
          {
            name: "PDV Store",
            Icon: StoreIcon,
            link: "/product-stores/pdvstore",
          },
          {
            name: "Wholesale Online Store",
            Icon: Shop2Icon,
            link: "/product-stores/wholesalestore",
          },
          {
            name: "Wholesale PDV Store",
            Icon: WarehouseIcon,
            link: "/product-stores/wholesalepdvstore",
          },
        ],
      },
      {
        name: "Payment",
        Icon: Payments,
        items: [
          {
            name: "Payment Methods",
            Icon: Payments,
            link: "/payment-methods",
          },
          {
            name: "Store Payment",
            Icon: CreditScoreIcon,
            link: "/payment-method-stores",
          },
        ],
      },
      {
        name: "Coupons",
        Icon: LocalActivityIcon,
        link: "/coupons",
      },
      {
        name: "Competitors",
        Icon: CatchingPokemonIcon,
        link: "/competitors",
      },
      {
        name: "Shipping",
        Icon: LocalShippingIcon,
        items: [
          {
            name: "Shipping Methods",
            Icon: DeliveryDining,
            link: "/shipping-methods",
          },
          {
            name: "Store Shipping",
            Icon: SendTimeExtension,
            link: "/shipping-method-stores",
          },
        ],
      },
    ],
  },
  {
    name: "Sales Info",
    Icon: AttachMoneyIcon,
    items: [
      {
        name: "Customers",
        link: "/customers",
        Icon: EmojiPeopleIcon,
        items: [
          {
            name: "Customer Groups",
            Icon: GroupsIcon,
            link: "/customer-groups",
          },
          {
            name: "Customer x Group",
            Icon: GroupAddIcon,
            link: "/customer-customer-groups",
          },
        ],
      },
      {
        name: "Carts",
        //link: "/orders?store=13",
        link: "/carts",
        Icon: ShoppingCartIcon,
      },
      {
        name: "Orders",
        //link: "/orders?store=13",
        link: "/orders",
        Icon: ShoppingBag,
        items: [
          {
            name: "Create Order",
            Icon: AddShoppingCart,
            link: "/orders/new",
          },
          {
            name: "Order Status",
            Icon: MoreHoriz,
            link: "/order-status",
          },
        ],
      },
      {
        name: "Website Contacts",
        //link: "/orders?store=13",
        link: "/website-contacts",
        Icon: ContactMailIcon,
      },
    ],
  },

  {
    name: "PDV",
    Icon: PointOfSaleIcon,
    items: [
      {
        name: "Cashiers",
        Icon: ReceiptIcon,
        link: "/cashiers",
      },
      {
        name: "Sellers",
        Icon: PeopleIcon,
        link: "/sellers",
      },
    ],
  },

  {
    name: "Marketplaces",
    Icon: StorefrontIcon,
    items: [
      {
        name: "Mercado Livre",
        Icon: MeliIcon,
        items: [],
      },
      {
        name: "B2W",
        Icon: B2wIcon,
        items: [],
      },
      {
        name: "Amazon",
        Icon: AmazonIcon,
        items: [],
      },
      {
        name: "Shopee",
        Icon: ShopeeIcon,
        items: [],
      },
    ],
  },
  {
    name: "Stores",
    Icon: StoreMallDirectoryIcon,
    link: "/stores",
    items: [
      {
        name: "Pages With Info",
        Icon: Feed,
        link: "/page/",
        items: [],
      },
      {
        name: "SOS Máscaras",
        Icon: SosMascarasIcon,
        link: "/stores/1",
        items: [],
      },
      {
        name: "Tainara Victory",
        Icon: TainaraVictoryIcon,
        link: "/stores/2",
        items: [],
      },
      {
        name: "Shop From Brazil",
        Icon: ShopFromBrazil,
        link: "/stores/3",
        items: [],
      },
    ],
  },
  {
    name: "System",
    Icon: SettingsIcon,
    items: [
      {
        name: "System Users",
        Icon: GroupIcon,
        link: "/users",
      },
      {
        name: "Currencies",
        link: "/currencies",
        Icon: AttachMoneyIcon,
      },
      {
        name: "Countries",
        Icon: PublicIcon,
        link: "/countries",
      },
      {
        name: "Languages",
        link: "/languages",
        Icon: LanguageIcon,
      },
      {
        name: "Product Conditions",
        link: "/product-conditions",
        Icon: InventoryIcon,
      },
    ],
  },
  {
    name: "Reports & Opt. Tools",
    Icon: Assessment,
    items: [
      {
        name: "Products x Inv. x Store",
        Icon: Summarize,
        link: "/product-inventory-store-report",
      },
      {
        name: "Errors Log",
        Icon: ErrorIcon,
        link: "/errorslogs",
      },
    ],
  },
];
export default Items;
