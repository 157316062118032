/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  Alert,
  AlertProps,
  Autocomplete,
  AutocompleteInputChangeReason,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { LogTrackUser } from "../../../types/log-track-user/log-track-user";
import Cookies from "js-cookie";

const stores_list: { id: number; name: string }[] = [];

//Initital Data so that it can function, based on the Example at the MUI Documentation
const initialRows: GridRowsProp = [
  {
    id: 1,
    category_id: 1,
    store_id: 1,
    category: "LOADING",
    store: "LOADING",
    dateCreated: new Date().toISOString(),
    lastLogin: new Date().toISOString(),
  },
];

//DATA GRID FUNCTIONS
//Autocomplete section
interface Category {
  id: number;
  name: string;
  id_name: string;
}

interface CategoryStore {
  id: number;
  category_id: number;
  store_id: number;
  category: string;
  store: string;
}

const useExecuteMutation = () => {
  return React.useCallback(
    (categoryStore: Partial<CategoryStore>) =>
      new Promise<Partial<CategoryStore>>((resolve, reject) =>
        setTimeout(() => {
          if (categoryStore.category_id === 0 || categoryStore.store_id === 0) {
            reject();
          } else {
            resolve(categoryStore);
          }
        }, 200)
      ),
    []
  );
};

function EditInputCellCategory(props: GridRenderCellParams) {
  //const { id, value, field } = props;
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChangeCategory = async (
    event: React.SyntheticEvent<Element, Event>,
    value_new: any
  ) => {
    //Before Everything, It has to verify if the changed data is not being duplicated
    if (value_new.id && props.row.store_id) {
      const check_exists = await api.get(
        `category-stores/check-exists?id=${id}&category_id=${value_new.id}&store_id=${props.row.store_id}`
      );
      if (!check_exists.data) {
        await apiRef.current.setEditCellValue({
          id,
          field,
          value: value_new.id,
        });
        //await apiRef.current.stopCellEditMode({ id, field });
      } else {
        //await apiRef.current.setEditCellValue({ id, field, value: value });
        apiRef.current.stopCellEditMode({ id, field });
        Swal.fire({
          icon: "error",
          title: "Duplicated Category & Store",
          text: "It can't be duplicated Category & Store. Category is already linked to the specified Store",
          //footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    }
  };

  const onInputChangeCategory = async (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    const results = await api.get(`/categories?page=0&limit=0&search=${value}`);
    setOptionsCategories([...results.data.items]);
  };

  const [open, setOpen] = React.useState(false);
  const [optionsCategories, setOptionsCategories] = React.useState<
    readonly Category[]
  >([]);

  const loading = open && optionsCategories.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const results = await api.get(`/categories?page=0&limit=0&search=`);
        setOptionsCategories([...results.data.items]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptionsCategories([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-category"
      sx={{ width: 250 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      //value={optionsCategories}
      //isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.id_name}
      options={optionsCategories}
      loading={loading}
      onInputChange={onInputChangeCategory}
      onChange={handleChangeCategory}
      /* onChange={(event: any, newValue: any) => {
          setValue2(newValue);
        }} */
      renderInput={(params) => (
        <TextField {...params} label="Select Category" />
      )}
    />
  );
}

function EditInputCellStore(props: GridRenderCellParams) {
  //const { id, value, field } = props;
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChangeStore = async (
    event: React.SyntheticEvent<Element, Event>,
    value_new: any
  ) => {
    if (value_new.id && props.row.category_id) {
      const check_exists = await api.get(
        `category-stores/check-exists?id=${id}&category_id=${props.row.category_id}&store_id=${value_new.id}`
      );
      //We need to verify if the data with the chosen Category ID and Store ID already exists
      //const check_exists = await api.get(`category-stores/checkExists?id=${newRow.id}&category_id=${newRow.category_id}&store_id=${newRow.store_id}`);
      //This list below sets the value according to the value changed
      if (!check_exists.data) {
        await apiRef.current.setEditCellValue({
          id,
          field,
          value: value_new.id,
        });
        //apiRef.current.stopCellEditMode({ id, field });
      } else {
        //await apiRef.current.setEditCellValue({ id, field, value: value });
        apiRef.current.stopCellEditMode({ id, field });
        Swal.fire({
          icon: "error",
          title: "Duplicated Category & Store",
          text: "It can't be duplicated Category & Store. Store is already linked to the specified Category",
          //footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    }
  };

  const onInputChangeStore = async (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    const results = await api.get(`/stores?page=0&limit=0&search=${value}`);
    setOptionsStores([...results.data.items]);
  };

  const [open, setOpen] = React.useState(false);
  const [optionsStores, setOptionsStores] = React.useState<readonly Category[]>(
    []
  );
  const loading = open && optionsStores.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const results = await api.get(`/stores?page=0&limit=0&search=`);
        setOptionsStores([...results.data.items]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptionsStores([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-store"
      sx={{ width: 250 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      //isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={stores_list}
      loading={loading}
      onInputChange={onInputChangeStore}
      onChange={handleChangeStore}
      renderInput={(params) => <TextField {...params} label="Select Store" />}
    />
  );
}

const renderSelectEditInputCellStore: GridColDef["renderCell"] = (params) => {
  return <EditInputCellStore {...params} />;
};

const renderEditInputCellCategory: GridColDef["renderCell"] = (params) => {
  return <EditInputCellCategory {...params} />;
};

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel) {
  //WHen both values are 1, it means it is a new insertion
  //Case for a new row
  if (oldRow.category_id === 1 && oldRow.store_id === 1) {
    //return `Confirm insertion for Store ID:'${newRow.store_id}'/' ${newRow.store}' and Category ID:'${newRow.category_id}'/' ${newRow.category}?`;
    return `Confirm insertion for Store ID:'${newRow.store_id}' and Category ID:'${newRow.category_id}'?`;
    //Case for a Row Update
  } else {
    if (newRow.category_id !== oldRow.category_id) {
      return `Change Category from ID:'${oldRow.category_id}'/' ${oldRow.category}' to ID:'${newRow.category_id}'/' ${newRow.category}`;
    }
    if (newRow.store_id !== oldRow.store_id) {
      return `Change Store from ID:'${oldRow.store_id}'/' ${oldRow.store}' to ID:'${newRow.store_id}'/' ${newRow.store}`;
    }
    return null;
  }
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;
  const [nextID, setNextID] = React.useState(0);

  const handleClick = async () => {
    if (nextID === 0) {
      await api.get(`category-stores/next-id`).then((res) => {
        setNextID(res.data);
        const id = res.data;
        setNextID(res.data + 1);
        setRows((oldRows) => [
          ...oldRows,
          {
            id,
            category_id: 1,
            store_id: 1,
            category: "Updated after Save",
            store: "Updated after Save",
            isNew: true,
          },
        ]);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: "category_id" },
        }));
      });
    } else {
      const id = nextID;
      setNextID(nextID + 1);
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          category_id: 1,
          store_id: 1,
          category: "Updated after Save",
          store: "Updated after Save",
          isNew: true,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "category_id" },
      }));
    }
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Record
      </Button>
    </GridToolbarContainer>
  );
}

export default function ListCategoriesStores() {
  //User Tracking Logging Section //////////////////////////////////////
  let [alreadySetLog, setAlreadySetLog] = useState(false);
  const location = useLocation();
  if (!alreadySetLog) {
    LogTrackUser(Cookies.get("username"), location.pathname);
    setAlreadySetLog(true as boolean);
  }
  ///////////////////////////////////////////////////////////////////

  const [loading, setLoading] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const [rowCountState, setRowCountState] = React.useState(0);
  const [categoryStores, setCategoryStores] = useState<
    {
      id: number;
      category_id: number;
      store_id: number;
      category: string;
      store: string;
      created_at: Date;
      updated_at: Date;
    }[]
  >([]);

  //const [nextID, setNextID] = React.useState(0);

  //const columns = ["ID", "Parent ID", "Name", "Path", "Actions", "Delete"];
  //const keys = ["id", "parent_id", "name", "path", "action", "delete"];
  const fetchData = async (
    page = 1,
    numberItemsPerPage = 100,
    language = 1
  ) => {
    await api
      .get(
        `/category-stores?page=${page}&limit=${numberItemsPerPage}&language_id=${language}&category_name=${categoryName}&store_id=${store}`
      )
      .then((res) => {
        setCategoryStores(res.data.items);
        setRows(res.data.items);
        // setTotalPages(res.data.total_pages);
        setRowCountState(res.data.total);
      });
  };

  //LANGUAGE SECTION
  const [language, setLanguage] = useState(0);
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(Number(event.target.value));
    fetchData(1, 1000, Number(event.target.value));
  };

  const [languages, setLanguages] = useState<{ id: number; name: string }[]>(
    []
  );
  const fetchLanguages = async () => {
    const response = await api.get(`languages`);
    setLanguages(response.data.items);
    setLanguage(1);
  };

  //CATEGORY NAME
  const [categoryName, setCategoryName] = useState("");
  const handleChangeCategoryName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryName(e.target.value);
  };

  const [store, setStore] = useState("0");
  const handleChangeStore = (event: SelectChangeEvent) => {
    setStore(event.target.value);
  };
  const [stores, setStores] = useState<{ id: number; name: string }[]>([]);
  const fetchStores = async () => {
    let language_id;
    if (Number(language) === 0) {
      language_id = 1;
    } else {
      language_id = language;
    }
    const response = await api.get(
      `stores?page=0&limit=500&language_id=${language_id}`
    );
    setStores(response.data.items);
    stores_list.length = 0;
    stores_list.push(...response.data.items);
  };

  const handleSearchClick = () => {
    fetchData(1, 1000, Number(language));
  };

  //DATAGRID SECTION
  //Add Record and Edit Section
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    //Message to Confirm SAVING
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    //Message to Confirm DELETION
    Swal.fire({
      title: `Are you sure to delete the ID ${id}?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        //Call API to delete de database
        api
          .delete(`category-stores/${id}`)
          .then((res) => {
            Swal.fire("Deleted!", "It has been deleted.", "success");
            fetchData(1, 1000, language);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      setCategoryStores(categoryStores.filter((row) => row.id !== id));
    }
  };

  const mutateRow = useExecuteMutation();
  const noButtonRef = React.useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null);

  const [snackbar, setSnackbar] = React.useState<Pick<
    AlertProps,
    "children" | "severity"
  > | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve, reject) => {
        const updatedRow = { ...newRow, isNew: false };
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
        //setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      // Make the HTTP request to save in the backend

      //Update DB
      const responseBackend = await api.get(`category-stores/${newRow.id}`);
      //It has to check whethe is updating or saving a new registry
      //WHen it is a new registry, responseBackend.data comes empty
      let categoryStore_update = responseBackend.data;
      if (categoryStore_update) {
        categoryStore_update.category_id = Number(newRow.category_id);
        categoryStore_update.store_id = Number(newRow.store_id);
        categoryStore_update.updated_at = new Date().toISOString();
        await api
          .put(`category-stores/${newRow.id}`, categoryStore_update)
          .catch((error) => {
            console.error(error.message);
          });

        await api.get(`category-stores/${newRow.id}`).then((res) => {
          newRow.category = res.data.category;
          newRow.store = res.data.store;
        });

        const response = await mutateRow(newRow);
        setSnackbar({
          children: "Category Store successfully Updated",
          severity: "success",
        });
        resolve(response);
        setPromiseArguments(null);
        //This else treats the case when inserting a new value
      } else {
        type CategoryStore = {
          category_id?: number;
          store_id?: number;
        };
        const categoryStore_creation: CategoryStore = {};
        categoryStore_creation.category_id = Number(newRow.category_id);
        categoryStore_creation.store_id = Number(newRow.store_id);
        await api
          .post(`category-stores`, categoryStore_creation)
          .catch((error) => {
            console.error(error.message);
          });
        await api.get(`category-stores/${newRow.id}`).then((res) => {
          newRow.category = res.data.category;
          newRow.store = res.data.store;
        });
        const response = await mutateRow(newRow);
        setSnackbar({
          children: "Category Store successfully Created",
          severity: "success",
        });
        resolve(response);
        setPromiseArguments(null);

        //Update the ROws and Category Stores consts
        fetchData(1, 1000, language);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({ children: "It can't be empty", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      width: 70,
    },
    {
      field: "category_id",
      headerName: "Category ID",
      type: "number",
      width: 250,
      editable: true,
      renderEditCell: renderEditInputCellCategory,
    },
    {
      field: "store_id",
      headerName: "Store ID",
      type: "number",
      width: 250,
      editable: true,
      renderEditCell: renderSelectEditInputCellStore,
    },
    {
      field: "category",
      headerName: "Category Name",
      //type: 'date',
      width: 250,
      editable: false,
    },
    {
      field: "store",
      headerName: "Store Name",
      //type: 'dateTime',
      width: 250,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  //LOAD INITIAL VALUES
  useEffect(() => {
    fetchData();
    fetchLanguages();
    fetchStores();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      fetchData(paginationModel.page + 1, paginationModel.pageSize);

      if (!active) {
        return;
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [paginationModel.page]);

  return (
    <Container maxWidth="xl" sx={{ width: "100%", mt: 2, mb: 4 }}>
      <Typography
        component="h1"
        variant="h5"
        sx={{
          paddingTop: "40px",
          fontWeight: "bold",
          fontSize: "32px",
          color: "#556cd6",
          paddingLeft: "20px",
        }}
      >
        Category & Store
      </Typography>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
            marginLeft: "8px",
            marginTop: "8px",
          }}
        >
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language"
            value={String(language)}
            label="Language"
            onChange={handleChangeLanguage}
          >
            <MenuItem value={0}>None</MenuItem>
            {languages.map((language, index) => (
              <MenuItem value={language.id} key={index}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
          }}
        >
          <InputLabel id="category-name-label">Category Name filter</InputLabel>
          <TextField
            //sx={{ mt: 1, minWidth: 3/4 }}
            sx={{ minWidth: 1 / 4 }}
            //fullWidth
            id={"Search"}
            type="text"
            label=" type here..."
            value={categoryName}
            onChange={handleChangeCategoryName}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginLeft: "8px",
        }}
      >
        <InputLabel id="store-select-label">Store</InputLabel>
        <Select
          sx={{
            minWidth: 1 / 11,
          }}
          labelId="store-select-label"
          id="store"
          value={store}
          label="Store"
          onChange={handleChangeStore}
        >
          <MenuItem value={0}>None</MenuItem>
          {stores.map((store, index) => (
            <MenuItem value={store.id} key={index}>
              {store.name}
            </MenuItem>
          ))}
        </Select>

        <Button
          variant="contained"
          sx={{ marginLeft: "8px" }}
          href=""
          onClick={handleSearchClick}
        >
          Filter
        </Button>
      </Box>

      <Box
        sx={{
          marginLeft: "8px",
          mt: "8px",
          height: 600,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        {renderConfirmDialog()}
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          initialState={{
            pagination: {
              paginationModel,
            },
          }}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[25, 50, 100]}
          rowCount={rowCountState}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          loading={loading}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
        {!!snackbar && (
          <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    </Container>
  );
}
